import * as React from 'react';
import { Box, CircularProgress } from '@mui/material'
import { useSnackbar } from 'notistack';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { NoPhotography as NoPhotographyIcon } from '@mui/icons-material';

const SvgComponent = ({ partNumber, model, tonnage, config }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [svgContent, setSvgContent] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const apiFetchSvg = async () => {
      if (!partNumber) return;

      try {
        const csrfToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('csrftoken='))
          ?.split('=')[1];
        const response = await fetch(`/api/heli/data/svg/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify({ partNumber })
        });

        if (response.ok) {
          const data = await response.json();
          if (data.svg_content) {
            setSvgContent(data.svg_content);
          } else if (model && tonnage && config) {
            // 如果没有SVG内容，尝试获取车型图片
            const machineImageResponse = await fetch(`/api/heli/data/machine-image/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
              },
              body: JSON.stringify({ model, tonnage, config })
            });

            if (machineImageResponse.ok) {
              const imageData = await machineImageResponse.json();
              if (imageData.status && imageData.image) {
                // 创建一个包含图片的SVG
                const imgSvg = `
                  <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                    <image width="100%" height="100%" href="data:image/png;base64,${imageData.image}" />
                  </svg>
                `;
                setSvgContent(imgSvg);
              } else {
                setSvgContent(null);
              }
            } else {
              setSvgContent(null);
            }
          } else {
            setSvgContent(null);
          }
        } else {
          setSvgContent(null);
        }
      } catch (error) {
        enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };
    apiFetchSvg();
  }, [partNumber, model, tonnage, config, enqueueSnackbar]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <CircularProgress size={60} color="error" />
      </Box>
    );
  }

  if (!svgContent) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <NoPhotographyIcon color="error" sx={{ fontSize: 60 }} />
      </Box>
    );
  }

  const modifiedSvgContent = svgContent
    .replace(/font-family:'[^']*'/g, "font-family:'Times New Roman', serif")
    .replace(/onclick="[^"]*"/g, '')
    .replace(/onmouseover="[^"]*"/g, '')
    .replace(/onmouseout="[^"]*"/g, '');

  return (
    <TransformWrapper initialScale={1.0} minScale={0.5} maxScale={5.0} centerOnInit={true}>
      <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
        <Box dangerouslySetInnerHTML={{ __html: modifiedSvgContent }} />
      </TransformComponent>
    </TransformWrapper>
  );
};

export default SvgComponent;
