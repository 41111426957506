import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  MenuBook as MenuBookIcon,
  ViewList as ViewListIcon,
  Image as ImageIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import UserStatus from '../components/UserStatus';
import Loading from '../components/Loading';
import SvgComponent from '../components/Svg';


const keyTextMap = {
  model: "请选择车型:",
  tonnage: "请选择吨位:",
  config: "请选择配置:",
  category: "请选择系统:",
  assembly: "请选择总成:",
};

function InHeli() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { model, tonnage, config, categoryCode, assembly } = useParams();
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [viewMode, setViewMode] = React.useState('svg'); // 'svg' 或 'list'

  // 获取数据
  const getData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      let action, params = {};

      if (assembly) {
        action = 'get_parts';
        params = { assembly };
      } else if (categoryCode) {
        action = 'get_assemblies';
        params = { model, tonnage, config, categoryCode };
      } else if (config) {
        action = 'get_categories';
        params = { model, tonnage, config };
      } else if (tonnage) {
        action = 'get_configs';
        params = { model, tonnage };
      } else if (model) {
        action = 'get_tonnages';
        params = { model };
      } else {
        action = 'get_models';
      }

      const response = await fetch('/api/heli/data/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
        },
        body: JSON.stringify({ action, params })
      });

      const result = await response.json();

      if (response.ok && result.status === 'success') {
        // 确保数据是数组类型
        const dataArray = Array.isArray(result.data) ? result.data : [];
        // 根据 action 设置正确的数据键
        const dataKey = action.replace('get_', '');
        setData({
          [dataKey]: dataArray.map(item =>
            typeof item === 'number' ? item.toString() : item
          )
        });
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [model, tonnage, config, categoryCode, assembly, enqueueSnackbar]);

  // 获取当前标题
  const getCurrentTitle = () => {
    if (assembly) return keyTextMap.assembly;
    if (categoryCode) return keyTextMap.category;
    if (config) return keyTextMap.config;
    if (tonnage) return keyTextMap.tonnage;
    if (model) return keyTextMap.tonnage;
    return keyTextMap.model;
  };

  // 监听路由参数变化
  React.useEffect(() => {
    getData();
  }, [getData]);

  // 搜索事件监听
  React.useEffect(() => {
    const handleHeliSearch = (event) => {
      const searchData = event.detail.searchData;
      setData(searchData);
      if (searchData.heli_search && searchData.heli_search.length === 0) {
        enqueueSnackbar('未找到相关配置', { variant: 'warning' });
      }
    };

    window.addEventListener('heli-search', handleHeliSearch);
    return () => {
      window.removeEventListener('heli-search', handleHeliSearch);
    };
  }, [enqueueSnackbar]);

  if (isLoading) {
    return <Loading />;
  }

  // 如果是图册显示页面，使用不同的布局
  if (assembly && data?.parts && !data?.heli_search) {
    return (
      <Box sx={{
        position: 'fixed',
        top: 64,  // AppBar 的高度
        left: { xs: 0, sm: 73, lg: 200 },  // 根据抽屉宽度调整
        right: 0,
        bottom: 0,
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* 标题栏 */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          flexShrink: 0,
        }}>
          {/* 左侧标题 */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MenuBookIcon sx={{ fontSize: 24, mr: 1 }} />
            <Typography variant="h6">
              零件图册
            </Typography>
          </Box>

          {/* 右侧按钮组 */}
          <Box sx={{ display: 'flex' }}>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(e, newMode) => newMode && setViewMode(newMode)}
              size="small"
            >
              <ToggleButton
                value="svg"
                sx={{
                  px: 2,
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    }
                  }
                }}
              >
                <ImageIcon sx={{ mr: 1 }} />
                图纸
              </ToggleButton>
              <ToggleButton
                value="list"
                sx={{
                  px: 2,
                  '&.Mui-selected': {
                    bgcolor: 'primary.main',
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'primary.dark',
                    }
                  }
                }}
              >
                <ViewListIcon sx={{ mr: 1 }} />
                列表
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>

        {/* 内容区域 */}
        <Box sx={{ flex: 1, overflow: 'hidden', p: 1 }}>
          {viewMode === 'svg' ? (
            <Box sx={{
              width: '100%',
              height: '100%',
              bgcolor: '#ffffff',
              borderRadius: 1,
              overflow: 'hidden',
              border: 1,
              borderColor: 'divider',
            }}>
              <SvgComponent
                partNumber={assembly}
                model={model}
                tonnage={tonnage}
                config={config}
              />
            </Box>
          ) : (
            <Paper elevation={0} sx={{
              width: '100%',
              height: '100%',
              borderRadius: 1,
              border: 1,
              borderColor: 'divider',
              overflow: 'hidden',
            }}>
              <TableContainer sx={{
                width: '100%',
                height: '100%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.3)',
                  },
                },
              }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>序号</TableCell>
                      <TableCell align='center'>图号</TableCell>
                      <TableCell align='center'>版本</TableCell>
                      <TableCell align='center'>名称</TableCell>
                      <TableCell align='center'>件数</TableCell>
                      <TableCell align='center'>备注</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.parts.map((part) => (
                      <TableRow hover key={`${part.item_number}-${part.part_number}`}>
                        <TableCell align='center'>{part.item_number}</TableCell>
                        <TableCell align='center'>{part.part_number}</TableCell>
                        <TableCell align='center'>{part.edition}</TableCell>
                        <TableCell align='center'>{part.name_zh}</TableCell>
                        <TableCell align='center'>{part.quantity}</TableCell>
                        <TableCell align='center'>{part.remark}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>
      </Box>
    );
  }

  // 其他页面的布局
  return (
    <Box sx={{
      position: 'fixed',  // 改为固定定位
      top: 64,
      left: { xs: 0, sm: 73, lg: 200 },
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* 标题栏 */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        flexShrink: 0,
      }}>
        {/* 左侧标题 */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MenuBookIcon sx={{ fontSize: 24, mr: 1 }} />
          <Typography variant="h6">
            零件图册
          </Typography>
        </Box>
      </Box>

      {/* 内容区域 */}
      <Box sx={{
        flex: 1,
        overflow: 'auto',
        p: 1,
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        {!assembly && !data?.heli_search && data && (
          <>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
                color: 'text.secondary',
              }}
            >
              {getCurrentTitle()}
            </Typography>
            <List sx={{
              width: '100%',
              borderRadius: 1,
              border: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper',
              mb: 2,
            }}>
              {Object.values(data)[0]?.map((item, index, array) => (
                <React.Fragment key={typeof item === 'string'
                  ? `${item}-${index}`
                  : item.code
                    ? `${item.code}-${index}`
                    : `${item.part_number}-${index}`}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        if (typeof item === 'string') {
                          const path = ['/heli'];
                          if (model) path.push(model);
                          if (tonnage) path.push(tonnage);
                          if (config) path.push(config);
                          path.push(item);
                          navigate(path.join('/'));
                        } else if (item.code) {
                          navigate(`/heli/${model}/${tonnage}/${config}/${item.code}`);
                        } else {
                          navigate(`/heli/${model}/${tonnage}/${config}/${categoryCode}/${item.part_number}`);
                        }
                      }}
                    >
                      <ListItemText
                        primary={typeof item === 'string'
                          ? item
                          : item.code
                            ? item.name
                            : `${item.part_number} ${item.name}`
                        }
                        sx={{ textAlign: 'center' }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {index !== array.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </>
        )}

        {/* 搜索结果显示 */}
        {data?.heli_search && (
          <Paper elevation={0} sx={{
            width: '100%',
            borderRadius: 1,
            border: 1,
            borderColor: 'divider',
            overflow: 'hidden',
            mb: 2,
          }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>车型</TableCell>
                    <TableCell align='center'>吨位</TableCell>
                    <TableCell align='center'>配置号</TableCell>
                    <TableCell align='center'>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.heli_search.map((item) => (
                    <TableRow hover key={`${item.machine_model__model}-${item.tonnage__tonnage}-${item.config__config_number}`}>
                      <TableCell align='center'>{item.machine_model__model}</TableCell>
                      <TableCell align='center'>{item.tonnage__tonnage}</TableCell>
                      <TableCell align='center'>{item.config__config_number}</TableCell>
                      <TableCell align='center'>
                        <IconButton
                          color="primary"
                          onClick={() => navigate(`/heli/${item.machine_model__model}/${item.tonnage__tonnage}/${item.config__config_number}`)}
                        >
                          <MenuBookIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Box>
    </Box>
  );
}

export default function Heli() {
  return (
    <UserStatus>
      <InHeli />
    </UserStatus>
  );
}
