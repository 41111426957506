import * as React from 'react';
import { useRoutes } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import routes from './routes';
import Nav from './Nav'
import { AuthProvider } from './context/AuthContext';

export default function App() {
  const element = useRoutes(routes)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <GlobalStyles
          styles={{
            'body': {
              overflow: 'hidden',
            },
          }}
        />
        <Box sx={{
          display: 'flex',
          height: '100vh',
          overflow: 'hidden',
        }}>
          <CssBaseline />
          <Nav />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              mt: '64px',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            {element}
          </Box>
        </Box>
      </AuthProvider>
    </ThemeProvider>
  );
}
