import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  Box,
  InputBase,
  Paper,
} from '@mui/material';
import {
  Menu as MenuIcon,
  AccountCircle,
  Search as SearchIcon,
  Construction as ConstructionIcon,
  MenuBook as MenuBookIcon,
  ManageSearch as ManageSearchIcon,
  Info as InfoIcon,
  Campaign as CampaignIcon,
  ElectricBolt as ElectricBoltIcon,
} from '@mui/icons-material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { useSnackbar } from 'notistack';
import Loading from './components/Loading';

const drawerWidth = 200;
const helicolor = '#DB0000';

const mainNavItems = [
  { id: 'heli', text: '零件图册', icon: <MenuBookIcon /> },
  { id: 'circuit', text: '电路图纸', icon: <ElectricBoltIcon /> },
  { id: 'alarm', text: '故障代码', icon: <ConstructionIcon /> },
  { id: 'forklift-info', text: '车号查询', icon: <ManageSearchIcon /> },
];

const bottomNavItems = [
  { id: 'announcements', text: '公告通知', icon: <CampaignIcon /> },
  { id: 'about', text: '关于我们', icon: <InfoIcon /> },
];

export default function Nav() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(isDesktop);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isLoggedIn, logout } = useAuth();
  const [searchValue, setSearchValue] = React.useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isSearching, setIsSearching] = React.useState(false);
  const drawerRef = React.useRef(null);

  const hideSearchRoutes = ['/about', '/announcements', '/signin', '/signup', '/me', '/home', '/forklift-info'];
  const shouldHideSearch = hideSearchRoutes.includes(location.pathname);

  React.useEffect(() => {
    setOpen(isDesktop);
    if (isTablet) {
      const handleClickOutside = (event) => {
        // 检查点击目标是否在 Drawer 外部
        if (drawerRef.current && !drawerRef.current.contains(event.target)) {
          setOpen(false);  // 关闭抽屉
        }
      };

      // 绑定事件监听器
      document.addEventListener('mousedown', handleClickOutside);

      // 清理事件监听器
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isDesktop, isTablet]);

  const getSearchType = (pathname) => {
    switch (pathname) {
      case '/alarm':
      case (pathname.match(/^\/alarm\//) || {}).input:
        return 'alarm';
      case '/heli':
      case (pathname.match(/^\/heli\//) || {}).input:
        return 'heli';
      case '/circuit':
      case (pathname.match(/^\/circuit\//) || {}).input:
        return 'circuit';
      default:
        return null;
    }
  };

  // 添加处理菜单点击的函数
  const handleMenuClick = () => {
    if (isMobile || isTablet) {
      setOpen(false);
    }
  };

  const handleSearch = async () => {
    const searchType = getSearchType(location.pathname);

    if (!searchType) {
      return;
    }

    // 检查搜索内容是否为空
    if (!searchValue.trim()) {
      enqueueSnackbar('请输入搜索内容', { variant: 'warning' });
      return;
    }

    setIsSearching(true);  // 开始搜索，显示加载状态

    try {
      if (searchType === 'heli') {
        const response = await fetch('/api/heli/search/', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
          },
          body: JSON.stringify({
            searchContent: searchValue,
          })
        });

        const responseData = await response.json();
        if (response.ok) {
          window.dispatchEvent(new CustomEvent(`${searchType}-search`, {
            detail: { searchData: responseData }
          }));
        } else {
          enqueueSnackbar(responseData.message || '搜索失败', { variant: 'error' });
        }
      } else if (searchType === 'alarm') {
        const response = await fetch('/api/alarm/search/', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
          },
          body: JSON.stringify({
            searchContent: searchValue,
          })
        });

        const responseData = await response.json();
        if (response.ok) {
          window.dispatchEvent(new CustomEvent(`${searchType}-search`, {
            detail: { searchData: responseData }
          }));
        } else {
          enqueueSnackbar(responseData.message || '搜索失败', { variant: 'error' });
        }
      } else if (searchType === 'circuit') {
        const response = await fetch('/api/circuit/search/', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
          },
          body: JSON.stringify({
            searchContent: searchValue,
          })
        });

        const responseData = await response.json();
        if (response.ok) {
          window.dispatchEvent(new CustomEvent(`${searchType}-search`, {
            detail: { searchData: responseData }
          }));
        } else {
          enqueueSnackbar(responseData.message || '搜索失败', { variant: 'error' });
        }
      }
    } catch (error) {
      console.error('Search error:', error);
      enqueueSnackbar('搜索出错，请稍后重试', { variant: 'error' });
    } finally {
      setIsSearching(false);  // 搜索结束，隐藏加载状态
      setSearchValue('');
    }
  };

  const getSearchPlaceholder = (pathname) => {
    switch (pathname) {
      case '/alarm':
      case (pathname.match(/^\/alarm\//) || {}).input:
        return '故障码搜索...';
      case '/heli':
      case (pathname.match(/^\/heli\//) || {}).input:
        return '配置号搜索...';
      case '/circuit':
      case (pathname.match(/^\/circuit\//) || {}).input:
        return '电路图搜索...';
      default:
        return '搜索...';
    }
  };

  const handleSignOut = async () => {
    try {
      const response = await fetch('/api/user/signout/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
        }
      });

      if (response.ok) {
        window.dispatchEvent(new CustomEvent('user-signout'));
        setAnchorEl(null);
        logout();
        navigate('/home');
        enqueueSnackbar('注销成功', { variant: 'success' });
      }
    } catch (error) {
      console.error('Signout error:', error);
      enqueueSnackbar('注销失败', { variant: 'error' });
    }
  };

  return (
    <>
      <AppBar position="fixed" color="inherit" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>

          {isMobile && (
            <IconButton
              color="inherit"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          {isTablet && !open && (
            <IconButton
              color="inherit"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography
            variant="h5"
            component={Link}
            to="/home"
            sx={{
              flexGrow: 1,
              color: helicolor,
              textDecoration: 'none',
              fontWeight: 700,
              letterSpacing: '.3rem',
            }}
          >
            HELI
          </Typography>
          {!shouldHideSearch && (
            <Paper
              component="form"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 300,
                mr: 1,
                ml: 1,
                backgroundColor: 'transparent',
                border: (theme) => `2px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.1)'
                  : 'rgba(0, 0, 0, 0.1)'}`,
                borderRadius: '24px',
                '&:hover': {
                  border: (theme) => `2px solid ${theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.3)'
                    : 'rgba(0, 0, 0, 0.3)'}`,
                },
              }}
            >
              <InputBase
                sx={{
                  ml: 2,
                  flex: 1,
                  color: 'inherit',
                }}
                placeholder={getSearchPlaceholder(location.pathname)}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSearch();
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{
                  color: helicolor,
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.15)',
                  }
                }}
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          )}
          <IconButton
            color="inherit"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {isLoggedIn ? [
              <MenuItem
                key="me"
                component={Link}
                to="/me"
                onClick={() => setAnchorEl(null)}
              >
                我的
              </MenuItem>,
              <MenuItem
                key="signout"
                onClick={() => {
                  handleSignOut();
                  setAnchorEl(null);
                }}
              >
                注销
              </MenuItem>
            ] : [
              <MenuItem
                key="signin"
                component={Link}
                to="/signin"
                onClick={() => setAnchorEl(null)}
              >
                登录
              </MenuItem>,
              <MenuItem
                key="signup"
                component={Link}
                to="/signup"
                onClick={() => setAnchorEl(null)}
              >
                注册
              </MenuItem>
            ]}
          </Menu>
        </Toolbar>
      </AppBar>
      {isTablet && open && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // 半透明黑色遮罩
            zIndex: theme.zIndex.drawer - 1, // 确保遮罩在抽屉下方
            pointerEvents: 'auto', // 使得遮罩区域能接收点击事件
          }}
        />
      )}
      <Drawer
        ref={drawerRef}
        variant={isMobile ? "temporary" : "permanent"}
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          width: !isMobile ? (open ? drawerWidth : theme.spacing(8)) : 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            ...(!open && !isMobile && {
              width: theme.spacing(8),
              overflowX: 'hidden',
            }),
          },
        }}
      >
        <Toolbar />
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
          <List>
            {mainNavItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton
                  component={Link}
                  to={`/${item.id}`}
                  onClick={handleMenuClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: 'center',
                    px: open ? 2.5 : 0,
                    width: !open ? '100%' : 'auto',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 0,
                      justifyContent: 'center',
                      ...((!open) && {
                        margin: 0,
                        width: '100%',
                      })
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      display: open ? 'block' : 'none',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Box sx={{ flexGrow: 1 }} />

          <List>
            {bottomNavItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton
                  component={Link}
                  to={`/${item.id}`}
                  onClick={handleMenuClick}
                  sx={{
                    minHeight: 48,
                    justifyContent: 'center',
                    px: open ? 2.5 : 0,
                    width: !open ? '100%' : 'auto',
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 0,
                      justifyContent: 'center',
                      ...((!open) && {
                        margin: 0,
                        width: '100%',
                      })
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      display: open ? 'block' : 'none',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {isSearching && <Loading />}
    </>
  );
}