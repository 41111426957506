import * as React from 'react';
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';
import Loading from '../components/Loading';
import { useAuth } from '../context/AuthContext';

export default function SignIn() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      setLoading(true);
      const response = await fetch('/api/user/signin/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
        },
        body: JSON.stringify({
          username: data.get('username'),
          password: data.get('password')
        })
      });

      const result = await response.json();

      if (response.ok) {
        enqueueSnackbar('登录成功', { variant: 'success' });
        login();
        setTimeout(() => {
          navigate('/home');
        }, 1500);
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setLoading(false);
    }
  };

  if (loading) {
    return (<Loading />);
  }

  return (
    <Box sx={{
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: 1,
    }}>
      <Box sx={{
        width: '100%',
        maxWidth: 'sm',
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 4,
        }}>
          <AccountCircleIcon sx={{ fontSize: 30, mr: 2 }} />
          <Typography variant="h5" component="h1">
            用户登录
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="用户名"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            登录
          </Button>
          <Grid container justifyContent="flex-end" component={Link} to='/signup'>
            <Grid item>
              没有账户？ 点击注册
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
