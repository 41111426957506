import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';

const menuItems = [
  {
    id: 'heli',
    title: '零件图册',
    subtitle: '安徽合力股份有限公司 Anhui Heli Co., Ltd.',
    image: '/heli.jpg',
  },
  {
    id: 'circuit',
    title: '电路图纸',
    subtitle: '安徽合力股份有限公司 Anhui Heli Co., Ltd.',
    image: '/circuit.png',
  },
  {
    id: 'alarm',
    title: '故障代码',
    subtitle: '安徽合力股份有限公司 Anhui Heli Co., Ltd.',
    image: '/alarm.png',
  },
  {
    id: 'forklift-info',
    title: '车号查询',
    subtitle: '安徽合力股份有限公司 Anhui Heli Co., Ltd.',
    image: '/forklift.jpg',
  },
];

export default function Home() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const getCardWidth = () => {
    if (isDesktop) return '45%';
    if (isTablet) return '45%';
    return '90%';
  };

  return (
    <Box sx={{
      position: 'fixed',
      top: 64,
      left: { xs: 0, sm: 73, lg: 200 },
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Box sx={{
        flex: 1,
        overflow: 'auto',
        p: 2,
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
          justifyContent: 'center',
          alignItems: 'flex-start',
          maxWidth: 1200,
          mx: 'auto',
          pt: 2,
        }}>
          {menuItems.map((item) => (
            <Card
              key={item.id}
              component={Link}
              to={`/${item.id}`}
              sx={{
                width: getCardWidth(),
                textDecoration: 'none',
                transition: 'all 0.2s',
                borderRadius: 2,
                overflow: 'hidden',
                boxShadow: (theme) => theme.shadows[2],
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: (theme) => theme.shadows[8],
                },
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height={220}
                  image={process.env.PUBLIC_URL + item.image}
                  alt={item.title}
                  sx={{
                    objectFit: 'cover',
                  }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                      fontWeight: 500,
                      fontSize: { xs: '1.25rem', sm: '1.5rem' },
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      fontSize: { xs: '0.875rem', sm: '0.975rem' },
                    }}
                  >
                    {item.subtitle}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  );
}