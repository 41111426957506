import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Construction as ConstructionIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import UserStatus from '../components/UserStatus';
import Loading from '../components/Loading';

function InAlarm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { code, brand, model } = useParams();
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  // 获取数据
  const getData = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const select = [];
      if (code) select.push({ key: 'code', value: code });
      if (brand) {
        select.push({ key: 'brand', value: brand });
        if (model) select.push({ key: 'model', value: model });
      }

      const response = await fetch('/api/alarm/data/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
        },
        body: JSON.stringify({ select })
      });

      const result = await response.json();
      if (response.ok) {
        setData(result);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [code, brand, model, enqueueSnackbar]);

  // 监听路由参数变化
  React.useEffect(() => {
    getData();
  }, [getData]);

  // 搜索事件监听
  React.useEffect(() => {
    const handleAlarmSearch = (event) => {
      const searchData = event.detail.searchData;
      setData({
        alarm_search: searchData.alarm_search
      });
      if (searchData.alarm_search && searchData.alarm_search.length === 0) {
        enqueueSnackbar('未找到相关故障代码', { variant: 'warning' });
      }
    };

    window.addEventListener('alarm-search', handleAlarmSearch);
    return () => {
      window.removeEventListener('alarm-search', handleAlarmSearch);
    };
  }, [enqueueSnackbar]);

  if (isLoading) {
    return <Loading />;
  }

  if (code && data?.alarm) {
    return (
      <Box sx={{
        position: 'fixed',
        top: 64,  // AppBar 的高度
        left: { xs: 0, sm: 73, lg: 200 },  // 根据抽屉宽度调整
        right: 0,
        bottom: 0,
        bgcolor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* 标题栏 */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
          flexShrink: 0,
        }}>
          {/* 左侧标题 */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ConstructionIcon sx={{ fontSize: 24, mr: 1 }} />
            <Typography variant="h6">
              故障代码
            </Typography>
          </Box>
        </Box>

        {/* 内容区域 */}
        <Box sx={{ flex: 1, overflow: 'hidden', p: 1 }}>
          <Paper elevation={0} sx={{
            width: '100%',
            height: '100%',
            borderRadius: 1,
            border: 1,
            borderColor: 'divider',
            overflow: 'hidden',
          }}>
            <TableContainer sx={{
              width: '100%',
              height: '100%',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0,0,0,0.1)',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(0,0,0,0.3)',
                },
              },
            }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>品牌</TableCell>
                    <TableCell align='center'>型号</TableCell>
                    <TableCell align='center'>故障代码</TableCell>
                    <TableCell align='center'>故障名称英文</TableCell>
                    <TableCell align='center'>故障名称中文</TableCell>
                    <TableCell align='center'>故障信息</TableCell>
                    <TableCell align='center'>解决方案</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.alarm_search.map((alarm) => (
                    <TableRow hover key={alarm.id}>
                      <TableCell align='center'>{alarm.brand}</TableCell>
                      <TableCell align='center'>{alarm.model}</TableCell>
                      <TableCell align='center'>{alarm.alarm_code}</TableCell>
                      <TableCell align='center'>{alarm.alarm_name_en}</TableCell>
                      <TableCell align='center'>{alarm.alarm_name_zh}</TableCell>
                      <TableCell align='center'>{alarm.alarm_info}</TableCell>
                      <TableCell align='center'>{alarm.alarm_solution}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    );
  }

  // 列表页面布局
  return (
    <Box sx={{
      position: 'fixed',
      top: 64,
      left: { xs: 0, sm: 73, lg: 200 },
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* 标题栏 */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        flexShrink: 0,
      }}>
        {/* 左侧标题 */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ConstructionIcon sx={{ fontSize: 24, mr: 1 }} />
          <Typography variant="h6">
            故障代码
          </Typography>
        </Box>
      </Box>

      {/* 内容区域 */}
      <Box sx={{
        flex: 1,
        overflow: 'auto',
        p: 1,
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        {/* 搜索结果表格 */}
        {data?.alarm_search && (
          <Paper elevation={0} sx={{
            width: '100%',
            borderRadius: 1,
            border: 1,
            borderColor: 'divider',
            overflow: 'hidden',
            mb: 2,
          }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>品牌</TableCell>
                    <TableCell align='center'>型号</TableCell>
                    <TableCell align='center'>故障代码</TableCell>
                    <TableCell align='center'>故障名称英文</TableCell>
                    <TableCell align='center'>故障名称中文</TableCell>
                    <TableCell align='center'>故障信息</TableCell>
                    <TableCell align='center'>解决方案</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.alarm_search.map((alarm) => (
                    <TableRow hover key={alarm.id}>
                      <TableCell align='center'>{alarm.brand}</TableCell>
                      <TableCell align='center'>{alarm.model}</TableCell>
                      <TableCell align='center'>{alarm.alarm_code}</TableCell>
                      <TableCell align='center'>{alarm.alarm_name_en}</TableCell>
                      <TableCell align='center'>{alarm.alarm_name_zh}</TableCell>
                      <TableCell align='center'>{alarm.alarm_info}</TableCell>
                      <TableCell align='center'>{alarm.alarm_solution}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        {/* 故障详情表格 */}
        {data?.alarms && !data?.alarm_search && (
          <Paper elevation={0} sx={{
            width: '100%',
            borderRadius: 1,
            border: 1,
            borderColor: 'divider',
            overflow: 'hidden',
            mb: 2,
          }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>故障代码</TableCell>
                    <TableCell align='center'>故障名称英文</TableCell>
                    <TableCell align='center'>故障名称中文</TableCell>
                    <TableCell align='center'>故障信息</TableCell>
                    <TableCell align='center'>解决方案</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.alarms.map((alarm) => (
                    <TableRow
                      hover
                      key={alarm.alarm_code}
                      onClick={() => navigate(`/alarm/${brand}/${alarm.alarm_code}`)}
                      sx={{ cursor: 'pointer' }}  // 添加指针样式表明可点击
                    >
                      <TableCell align='center'>{alarm.alarm_code}</TableCell>
                      <TableCell align='center'>{alarm.alarm_name_en}</TableCell>
                      <TableCell align='center'>{alarm.alarm_name_zh}</TableCell>
                      <TableCell align='center'>{alarm.alarm_info}</TableCell>
                      <TableCell align='center'>{alarm.alarm_solution}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        {/* 品牌或型号列表 */}
        {(data?.brand || data?.model) && (
          <>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
                color: 'text.secondary',
              }}
            >
              {brand ? '请选择型号:' : '请选择控制器:'}
            </Typography>
            <List sx={{
              width: '100%',
              borderRadius: 1,
              border: 1,
              borderColor: 'divider',
              bgcolor: 'background.paper',
              mb: 2,
            }}>
              {(brand ? data.model : data.brand)?.map((item, index, array) => (
                <React.Fragment key={item}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        if (brand) {
                          navigate(`/alarm/${brand}/${item}`);
                        } else {
                          navigate(`/alarm/${item}`);
                        }
                      }}
                    >
                      <ListItemText primary={item} sx={{ textAlign: 'center' }} />
                    </ListItemButton>
                  </ListItem>
                  {index !== array.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </>
        )}
      </Box>
    </Box>
  );
}

export default function Alarm() {
  return (
    <UserStatus>
      <InAlarm />
    </UserStatus>
  );
}
