import * as React from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
} from '@mui/material';
import {
    ElectricBolt as ElectricBoltIcon,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import UserStatus from '../components/UserStatus';
import Loading from '../components/Loading';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

function InCircuit() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { name } = useParams();
    const [data, setData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    // 获取数据
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/circuit/data/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
                    },
                    body: JSON.stringify({ name })
                });
                const result = await response.json();

                if (response.ok && result.status) {
                    setData(result);
                } else {
                    enqueueSnackbar(result.message || '获取数据失败', { variant: 'error' });
                }
            } catch (error) {
                enqueueSnackbar('网络错误', { variant: 'error' });
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [name, enqueueSnackbar]);

    // 搜索事件监听
    React.useEffect(() => {
        const handleCircuitSearch = (event) => {
            const searchData = event.detail.searchData;
            setData(searchData);
            if (searchData.circuits && searchData.circuits.length === 0) {
                enqueueSnackbar('未找到相关图纸名称', { variant: 'warning' });
            }
        };

        window.addEventListener('circuit-search', handleCircuitSearch);
        return () => {
            window.removeEventListener('circuit-search', handleCircuitSearch);
        };
    }, [enqueueSnackbar]);

    if (isLoading) {
        return <Loading />;
    }

    // 如果有SVG内容,显示图纸
    if (data?.svg_content) {
        return (
            <Box sx={{
                position: 'fixed',
                top: 64,
                left: { xs: 0, sm: 73, lg: 200 },
                right: 0,
                bottom: 0,
                bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
            }}>
                {/* 标题栏 */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 2,
                    borderBottom: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                }}>
                    <ElectricBoltIcon sx={{ fontSize: 24, mr: 1 }} />
                    <Typography variant="h6">
                        电路图纸
                    </Typography>
                </Box>

                {/* SVG显示区域 */}
                <Box sx={{ flex: 1, p: 1, overflow: 'hidden' }}>
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        bgcolor: '#ffffff',
                        borderRadius: 1,
                        overflow: 'hidden',
                        border: 1,
                        borderColor: 'divider',
                    }}>
                        <TransformWrapper
                            initialScale={1.0}
                            minScale={0.5}
                            maxScale={5.0}
                            centerOnInit={true}
                        >
                            <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
                                <Box dangerouslySetInnerHTML={{ __html: data.svg_content }} />
                            </TransformComponent>
                        </TransformWrapper>
                    </Box>
                </Box>
            </Box>
        );
    }

    // 显示列表
    return (
        <Box sx={{
            position: 'fixed',
            top: 64,
            left: { xs: 0, sm: 73, lg: 200 },
            right: 0,
            bottom: 0,
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
        }}>
            {/* 标题栏 */}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                p: 2,
                borderBottom: 1,
                borderColor: 'divider',
                bgcolor: 'background.paper',
            }}>
                <ElectricBoltIcon sx={{ fontSize: 24, mr: 1 }} />
                <Typography variant="h6">
                    电路图纸
                </Typography>
            </Box>

            {/* 列表区域 */}
            <Box sx={{
                flex: 1,
                overflow: 'auto',
                p: 1,
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
            }}>
                <Typography
                    variant="body1"
                    sx={{
                        mb: 2,
                        color: 'text.secondary',
                    }}
                >
                    请选择图纸:
                </Typography>
                <List sx={{
                    width: '100%',
                    borderRadius: 1,
                    border: 1,
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    mb: 2,
                }}>
                    {data?.circuits?.map((item, index, array) => (
                        <React.Fragment key={item}>
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => navigate(`/circuit/${item}`)}
                                >
                                    <ListItemText primary={item} sx={{ textAlign: 'center' }} />
                                </ListItemButton>
                            </ListItem>
                            {index !== array.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Box>
    );
}

export default function Circuit() {
    return (
        <UserStatus>
            <InCircuit />
        </UserStatus>
    );
} 