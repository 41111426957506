import { Navigate } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
import Heli from './pages/Heli'
import Alarm from './pages/Alarm'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Me from './pages/Me'
import Announcements from './pages/Announcements'
import ForkliftInfo from './pages/ForkliftInfo'
import Circuit from './pages/Circuit'

const router = [
  {
    path: '/announcements',
    element: <Announcements />
  },
  {
    path: '/forklift-info',
    element: <ForkliftInfo />
  },
  {
    path: '/signup',
    element: <SignUp />
  },
  {
    path: '/signin',
    element: <SignIn />
  },
  {
    path: '/me',
    element: <Me />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/heli',
    element: <Heli />
  },
  {
    path: '/heli/:model',
    element: <Heli />
  },
  {
    path: '/heli/:model/:tonnage',
    element: <Heli />
  },
  {
    path: '/heli/:model/:tonnage/:config',
    element: <Heli />
  },
  {
    path: '/heli/:model/:tonnage/:config/:categoryCode',
    element: <Heli />
  },
  {
    path: '/heli/:model/:tonnage/:config/:categoryCode/:assembly',
    element: <Heli />
  },
  {
    path: '/alarm',
    element: <Alarm />
  },
  {
    path: '/alarm/:brand',
    element: <Alarm />
  },
  {
    path: '/alarm/:brand/:model',
    element: <Alarm />
  },
  {
    path: '/circuit',
    element: <Circuit />
  },
  {
    path: '/circuit/:name',
    element: <Circuit />
  },
  {
    path: '/',
    element: <Navigate to='/home' />
  }
]

export default router