import * as React from 'react';
import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Person as PersonIcon } from '@mui/icons-material';
import UserStatus from '../components/UserStatus';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';

function InSignUp() {
  const [userInfo, setUserInfo] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // 请求数据
  React.useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await fetch('/api/user/info/', {
          method: 'GET',
          credentials: 'include',
        });

        const data = await response.json();

        if (response.ok) {
          setUserInfo(data);
        } else {
          enqueueSnackbar(data.message || '获取用户信息失败', { variant: 'error' });
          if (response.status === 401 || response.status === 403) {
            setTimeout(() => {
              navigate('/signin');
            }, 1500);
          }
        }
      } catch (error) {
        enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      } finally {
        setIsLoading(false);
      }
    };
    getUserInfo();
  }, [navigate, enqueueSnackbar]);

  // 提交数据
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get('password') !== data.get('confirm_password')) {
      enqueueSnackbar('两次输入密码不一致', { variant: 'error' });
      return;
    }

    try {
      const response = await fetch('/api/user/info/change/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
        },
        body: JSON.stringify({
          username: data.get('username'),
          password: data.get('password'),
          last_name: data.get('last_name'),
          first_name: data.get('first_name'),
          email: data.get('email')
        })
      });

      const result = await response.json();

      if (response.ok) {
        enqueueSnackbar(result.message, { variant: 'success' });
        if (data.get('password')) {
          setTimeout(() => {
            navigate('/signin');
          }, 1500);
        }
      } else {
        enqueueSnackbar(result.message, { variant: 'error' });
        setIsLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setIsLoading(false);
    }
  };

  // 加载中
  if (isLoading) {
    return (<Loading />);
  }

  return (
    <Box sx={{
      position: 'fixed',
      top: 64,
      left: { xs: 0, sm: 73, lg: 200 },
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* 标题栏 */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        flexShrink: 0,
      }}>
        {/* 左侧标题 */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PersonIcon sx={{ fontSize: 24, mr: 1 }} />
          <Typography variant="h6">
            个人中心
          </Typography>
        </Box>
      </Box>

      {/* 内容区域 */}
      <Box sx={{
        flex: 1,
        overflow: 'auto',
        p: 1,
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Paper elevation={0} sx={{
            p: 3,
            mb: 3,
            borderRadius: 1,
            border: 1,
            borderColor: 'divider',
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  id="username"
                  label="用户名"
                  name="username"
                  autoComplete="username"
                  defaultValue={userInfo.username}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={0} sx={{
            p: 3,
            mb: 3,
            borderRadius: 1,
            border: 1,
            borderColor: 'divider',
          }}>
            <Typography variant="h6" sx={{ mb: 2 }}>个人信息</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="姓氏"
                  name="last_name"
                  autoComplete="family-name"
                  defaultValue={userInfo.last_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="first_name"
                  label="名字"
                  name="first_name"
                  autoComplete="given-name"
                  defaultValue={userInfo.first_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="邮箱地址"
                  name="email"
                  autoComplete="email"
                  defaultValue={userInfo.email}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper elevation={0} sx={{
            p: 3,
            mb: 3,
            borderRadius: 1,
            border: 1,
            borderColor: 'divider',
          }}>
            <Typography variant="h6" sx={{ mb: 2 }}>修改密码</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="设置新密码"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  helperText='如需修改密码请填写新密码 否则留空'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="confirm_password"
                  label="确认新密码"
                  type="Password"
                  id="confirm_password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
          </Paper>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            保存更改
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default function Me() {
  return (
    <UserStatus>
      <InSignUp />
    </UserStatus>
  );
}
