import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Paper,
} from '@mui/material';
import { Campaign as CampaignIcon } from '@mui/icons-material';
import Loading from '../components/Loading';

export default function Announcements() {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/announcements/data/`)
      .then(response => response.json())
      .then(data => {
        // 倒序排列公告数据
        const sortedAnnouncements = (data.announcements_data || []).sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        setAnnouncements(sortedAnnouncements);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching announcements:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={{
      position: 'fixed',
      top: 64,
      left: { xs: 0, sm: 73, lg: 200 },
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* 标题栏 */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        flexShrink: 0,
      }}>
        {/* 左侧标题 */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CampaignIcon sx={{ fontSize: 24, mr: 1 }} />
          <Typography variant="h6">
            公告通知
          </Typography>
        </Box>
      </Box>

      {/* 内容区域 */}
      <Box sx={{
        flex: 1,
        overflow: 'auto',
        p: 1,
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {announcements.map((item, index) => (
            <Paper
              key={item.date}
              elevation={0}
              sx={{
                mb: 2,
                borderRadius: 1,
                border: 1,
                borderColor: 'divider',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.02)',
                }
              }}
            >
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      textAlign={'center'}
                      sx={{ mb: 1 }}
                    >
                      {item.date}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body1"
                      color="text.primary"
                      textAlign={'center'}
                      sx={{
                        whiteSpace: 'pre-line',
                        lineHeight: 1.8
                      }}
                    >
                      {item.content}
                    </Typography>
                  }
                />
              </ListItem>
              {index < announcements.length - 1 && (
                <Divider component="li" sx={{ my: 1 }} />
              )}
            </Paper>
          ))}
        </List>
      </Box>
    </Box>
  );
}
