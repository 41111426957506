import * as React from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  InputBase,
} from '@mui/material';
import {
  Search as SearchIcon,
  ManageSearch as ManageSearchIcon,
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import UserStatus from '../components/UserStatus';
import Loading from '../components/Loading';

function InForkliftInfo() {
  const helicolor = '#DB0000';

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  // 搜索处理函数
  const handleSearch = async () => {
    if (!searchValue.trim()) {
      enqueueSnackbar('请输入11位出厂编号......', { variant: 'warning' });
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch('/api/forklift-analyze/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': document.cookie.split('; ').find(row => row.startsWith('csrftoken='))?.split('=')[1],
        },
        body: JSON.stringify({
          FORKLIFT_NUM: searchValue.trim().toUpperCase()
        })
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || '搜索失败');
      }

      if (result.basic_info && result.basic_info['叉车型号']) {
        setData(result);
      } else {
        enqueueSnackbar('未找到相关车辆信息', { variant: 'warning' });
        setData(null);
      }
    } catch (error) {
      console.error('Search error:', error);
      enqueueSnackbar(error.message || '搜索失败，请稍后重试', { variant: 'error' });
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  // 处理回车键搜索
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box sx={{
      position: 'fixed',
      top: 64,
      left: { xs: 0, sm: 73, lg: 200 },
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* 标题栏 */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        p: 2,
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        flexShrink: 0,
      }}>
        <ManageSearchIcon sx={{ fontSize: 24, mr: 1 }} />
        <Typography variant="h6">
          车号查询
        </Typography>
      </Box>

      {/* 内容区域 */}
      <Box sx={{
        flex: 1,
        overflow: 'auto',
        p: 1,
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}>
        {/* 搜索框 */}
        <Paper
          elevation={0}
          component="form"
          sx={{
            p: 2,
            mb: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InputBase
            fullWidth
            sx={{
              ml: 2,
              flex: 1,
              backgroundColor: 'transparent',
              border: (theme) => `2px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'}`,
              borderRadius: '24px',
              '&:hover': {
                border: (theme) => `2px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.3)'
                  : 'rgba(0, 0, 0, 0.3)'}`,
              },
              '& input': {
                padding: '8px 12px',
              }
            }}
            placeholder="请输入11位出厂编号......"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value.toUpperCase())}
            onKeyPress={handleKeyPress}
          />
          <IconButton
            onClick={handleSearch}
            sx={{
              ml: 1,
              color: helicolor,
              backgroundColor: 'transparent',
              border: (theme) => `2px solid ${theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.1)'
                : 'rgba(0, 0, 0, 0.1)'}`,
              borderRadius: '24px',
              '&:hover': {
                border: (theme) => `2px solid ${theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.3)'
                  : 'rgba(0, 0, 0, 0.3)'}`,
              },
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>

        {/* 基本信息表格 */}
        {data?.basic_info && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.secondary' }}>
              基本信息
            </Typography>
            <Paper elevation={0} sx={{
              width: '100%',
              borderRadius: 1,
              border: 1,
              borderColor: 'divider',
              overflow: 'hidden',
              mb: 2,
            }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {Object.entries(data.basic_info).map(([key, value], index) => (
                      <TableRow hover key={index}>
                        <TableCell align='left' sx={{ width: '30%' }}>{key}</TableCell>
                        <TableCell align='left'>{value || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}

        {/* 配置信息表格 */}
        {data?.config_info?.length > 0 && (
          <>
            <Typography variant="subtitle1" sx={{ mb: 2, color: 'text.secondary' }}>
              配置信息
            </Typography>
            <Paper elevation={0} sx={{
              width: '100%',
              borderRadius: 1,
              border: 1,
              borderColor: 'divider',
              overflow: 'hidden',
              mb: 2,
            }}>
              <TableContainer>
                <Table>
                  <TableBody>
                    {data.config_info.map((item, index) => (
                      <TableRow hover key={index}>
                        <TableCell align='left' sx={{ width: '30%' }}>{item.描述}</TableCell>
                        <TableCell align='left'>{item.值 || '-'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
}

export default function ForkliftInfo() {
  return (
    <UserStatus>
      <InForkliftInfo />
    </UserStatus>
  );
} 