import * as React from 'react';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import Loading from '../components/Loading';

export default function SignUp() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [checked, setChecked] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword')

    if (username.length < 5) {
      enqueueSnackbar('必须输入用户名且长度不小于5位', { variant: 'error' });
      return;
    }

    if (password.length < 8) {
      enqueueSnackbar('必须输入密码且长度不小于8位', { variant: 'error' });
      return;
    }

    if (password !== confirmPassword) {
      enqueueSnackbar('两次输入密码不一致', { variant: 'error' });
      return;
    }
    try {
      setLoading(true)
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/user/signup/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ username, password })
      });
      const signUpInfo = await response.json();
      if (response.ok) {
        enqueueSnackbar(signUpInfo.message, { variant: 'success' });
        setTimeout(() => {
          navigate('/signin');
        }, 1500);
      } else {
        enqueueSnackbar(signUpInfo.message, { variant: 'error' });
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(`网络错误:${error}`, { variant: 'error' });
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Box sx={{
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: 1,
    }}>
      <Box sx={{ 
        width: '100%',
        maxWidth: 'sm',
        overflow: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}>
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          mb: 4,
        }}>
          <PersonAddIcon sx={{ fontSize: 30, mr: 2 }} />
          <Typography variant="h5" component="h1">
            用户注册
          </Typography>
        </Box>

        <Box component="form" noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required={true}
            fullWidth
            id="username"
            label="用户名"
            name="username"
            autoComplete="username"
          />
          <TextField
            margin="normal"
            required={true}
            fullWidth
            name="password"
            label="密码"
            type="password"
            id="password"
            autoComplete="new-password"
          />
          <TextField
            margin="normal"
            required={true}
            fullWidth
            name="confirmPassword"
            label="确认密码"
            type="Password"
            id="confirmPassword"
            autoComplete="new-password"
          />
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                name="checked"
                color="primary"
              />
            }
            label={
              <Typography>
                同意 &nbsp;
                <Typography component={Link} to="/about">
                  用户协议
                </Typography>
              </Typography>
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            注册
          </Button>
          <Grid container justifyContent="flex-end" component={Link} to='/signin'>
            <Grid item>
              已有帐户？ 点击登录
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
